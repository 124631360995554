import React from "react"
import WistiaPlayer from "./WistiaPlayer"
import { Link } from "gatsby"
import { routes } from "@/utils/routes"

export type VideoType = {
  title: string
  description?: string
  placeholder: {
    asset: {
      url: string
    }
  }
  videoUrl?: string
}

type Props = {
  video: VideoType
}

const VideoPage: React.FC<Props> = ({ video }) => {
  return (
    <div className="py-24">
      <div className="container">
        <h2 className="uppercase text-[36px] sm:text-[46px] font-medium leading-[0.9] pb-4 mb-8">
          {video.description}
        </h2>

        {video.videoUrl != null ?
          <WistiaPlayer
            videoStyle={{
              width: "100%",
              height: "100%",
            }}
            videoID={video.videoUrl}
            className="aspect-video"
          />
          :
          <img src={video.placeholder.asset.url} alt={video.title} className="w-full" />
        }

        <div className="md:mt-8 mb-8 grid gap-8 grid-cols-1 md:grid-cols-2">
          <div>
          </div>

          <div className="w-full uppercase flex items-center justify-self-end">
            <h2 className="text-[42px] leading-none pt-4 lg:pl-12">
              <Link
                className="text-primary hover:opacity-90"
                to={routes.donate()}
              >
                Donate Now
              </Link>
            </h2>
          </div>
        </div>

      </div>
    </div>
  )
}

export default VideoPage
