import React, { CSSProperties, useEffect } from "react"
import WistiaPlayerApiLoader from "@/utils/wistia"
import clsx from "clsx"

type Props = {
  videoID: string
  className?: string
  videoClassName?: string
  videoStyle?: CSSProperties
}

const WistiaPlayer: React.FC<Props> = ({
  videoID,
  className,
  videoClassName,
  videoStyle,
}) => {
  useEffect(() => {
    WistiaPlayerApiLoader.load((Wistia) => {
      console.log("Wistia loaded...")
      Wistia.embed(videoID, {})
    })
  }, [videoID])
  return (
    <div className={clsx("wistia-player", className)}>
      <div
        style={{
          height: 300,
          width: 533,
          backgroundColor: "#f7f7f7",
          ...videoStyle,
        }}
        id={`wistia_${videoID}`}
        className={clsx("wistia_embed", videoClassName)}
      ></div>
    </div>
  )
}

export default WistiaPlayer
